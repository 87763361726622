* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
a {
  outline: 0;
}

.button-main:focus {
  outline: 0 !important;
}

.nav-name {
  margin: 100;
  color: black;
}

.nav-link:hover {
  color: #0149b4 !important;
}

@media only screen and (max-width: 991px) {
  #desktopSchoolAndEducation {
    display: none;
  }
  #technologyFeatureContainer-desktop {
    display: none;
  }
  h1.mobile-blogPaperComing {
    margin-top: 16px;
    margin-bottom: 32px;
    font-size: 20px;
    color: #818383;
    font-weight: 600;
  }
  div.desktop-blogPaperComing {
    display: none;
  }
  .requestForDemo button {
    margin-top: 24px !important;
    width: 189px !important;
  }
  .requestForDemo p {
    margin-top: 8px !important;
    font-size: 16px !important;
    font-weight: 400;
    margin-bottom: 0px !important;
  }
  .requestForDemo h1 {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
  .requestForDemo {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .blog-paper-main h1 {
    font-size: 25px !important;
    margin-top: 8px;
  }
  .blog-paper-main img {
    width: 100%;
    height: 40px;
  }

  .blog-section-heading {
    font-size: 25px !important;
    font-weight: 600 !important;
    margin-bottom: 42px !important;
    color: #222323;
  }
  div.blog-section {
    padding-top: 64px;
    padding-bottom: 50px;
  }
  #comprehensive {
    margin-top: 48px !important;
    margin-bottom: 16px !important;
    font-weight: 600 !important;
  }
  h2.full-WidthGrid ~ p {
    color: #818383;
    font-size: 16px;
    margin: auto;
    width: 280px;
    margin-bottom: 48px !important;
  }
  h2.full-WidthGrid {
    margin-top: 32px !important;
    margin-bottom: 16px !important;
    font-weight: 600 !important;
  }
  #fullWidthGrid {
    margin-top: 80px !important;
    padding-bottom: 64px !important;
  }
  .blog-paper-main {
    margin-bottom: 32px;
    height: 216px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .blogPaperComing {
    margin-left: 15px;
    margin-right: 15px;
    height: 88px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .blogPaperComing img {
    height: 40px !important;
  }
  #mobile-technology {
    display: block;
  }
  #desktop-technology {
    display: none;
  }
  .contactBg {
    background-image: url(./images/Contact_mobile_bg.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  #standardized {
    margin-top: 0px !important;
  }
  .standardized-img {
    height: 242px !important;
    padding-top: 0px !important;
  }
  #reduceHuman {
    margin-bottom: 8px !important;
  }
  #Obtain-scores {
    margin-bottom: 8px !important;
  }
  #schoolAndEducation {
    margin-top: 32px !important;
  }
  .titleMessage {
    font-size: 20px !important;
    margin-top: 8px !important;
    margin-bottom: 16px;
    margin-left: 7px !important;
  }
  .titleMessage ~ p {
    font-size: 16px;
    color: #222323 !important;
    margin-bottom: 8px;
    text-align: left;
    padding-left: 35px !important;
    margin-top: 0px !important;
  }
  .boxUsecase-img {
    width: 290px !important;
    height: 242px !important;
    display: block;
    margin: auto;
  }
  #mobileSchoolAndEducationImg {
    width: 290px !important;
    height: 242px !important;
    padding-top: 0px !important;
    display: block;
    margin: auto;
  }
  #aispeakCanBeUsed {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .ai-uses > p {
    font-size: 25px !important;
    width: 264px;
    height: 64px;
    padding-left: 40px;
    margin-bottom: 60px !important;
    padding: 0;
    margin: auto;
  }
  #ai-usecase {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .partners {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .ai-usecase {
    padding-bottom: 80px !important;
  }
  #scalableImg {
    margin-top: 40px !important;
  }
  .widget-item p {
    margin: 0 16px;
  }
  #widget {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .widget-item h1 {
    margin-top: 26px;
    font-size: 20px !important;
  }
  .widget-item-div {
    padding-bottom: 64px !important;
    padding-top: 0px !important;
  }
  #homeMain button {
    padding: 16px 18px !important;
    margin-bottom: 30px;
  }
  #homeMain p {
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-bottom: 4px;
  }
  #main-div-grid {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  #homeMainTopGrid {
    padding-top: 120px !important;
    padding-left: 0px !important;
  }
  .main-left > h1 {
    font-size: 32px !important;
    font-weight: 600 !important;
    margin-bottom: 16px !important;
  }

  /* new code above */
  #instantlyCheckSpeaking {
    width: 100% !important;
  }
  #homeMainTopGrid {
    padding: 0;
    margin: auto;
    text-align: center;
    justify-content: space-around;
  }
  #homeMainTopGrid .MuiGrid-grid-sm-6 {
    max-width: 100%;
  }
}
.vocabulary {
  margin: auto;
  outline: none;
  background-color: #ffffff;
  width: 1110px;
}
.phrases {
  margin: auto;
  outline: none;
  background-color: #ffffff;
  width: 1110px;
}
.presentation {
  margin: auto;
  outline: none;
  background-color: #ffffff;
  width: 1110px;
  height: 100%;
  overflow: auto;
}
.presentation > div {
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  width: 91%;
  margin: auto;
  margin-bottom: 44px;
}
.blue-span {
  color: #1371e2;
}
.red-span {
  color: #e21313;
}
.presentation > div > p {
  margin: 45px 36px 32px 40px;
  font-size: 16px;
  color: #818383;
  font-weight: 500;
}
.presentationContainer {
  display: flex;
  margin-left: 40px;
  justify-content: space-around;
  width: 75%;
}

.presentation-stats {
  width: 30%;
  /* margin-bottom: 38px; */
}

.presentation-stats > h1 {
  color: #818383;
  font-size: 20px;
  font-weight: 600;
}
.presentation-stats > div {
  margin-top: 24px;
  width: 100%;
  height: 27px;
  border-radius: 24px;
  background-color: #f4f4f4;
}
.presentation-stats > div > div {
  margin-top: 24px;
  height: 27px;
  border-radius: 24px;
}
.presentation-stats > p {
  margin-top: 12px;
  font-size: 16px;
  color: #818383;
  font-weight: 400;
}
.qAndA {
  margin: auto;
  outline: none;
  width: 1110px;
  background-color: #ffffff;
}
.vocabulary > h1 {
  text-align: center;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 80px;
  color: #222323;
  font-size: 32px;
  font-weight: 600;
}

.phrases > h1 {
  text-align: center;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 80px;
  color: #222323;
  font-size: 32px;
  font-weight: 600;
}

.presentation > h1 {
  text-align: center;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 31px;
  color: #222323;
  font-size: 32px;
  font-weight: 600;
}

.qAndA > h1 {
  text-align: center;
  margin: auto;
  margin-bottom: 80px;
  margin-top: 10px;
  color: #222323;
  font-size: 32px;
  font-weight: 600;
}

#vocabularyMain {
  display: flex;
  justify-content: center;
  margin: 0px 112px 138px;
}
.qAndA-main {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 102px;
}

.vocabulary-div1 {
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  width: 40%;
}

.phrases-div1 {
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  width: 80%;
  /* display: flex; */
  margin: auto;
  margin-bottom: 110px;
}
.phrases-div1 > h3 {
  margin-left: 40px;
  margin-top: 40px;
  font-size: 20px;
  color: #818383;
  margin-bottom: 16px;
}

.phrases-div1 > h1 {
  font-size: 25px;
  font-weight: 600;
  color: #222323;
  margin: 0px 0px 40px 40px;
}

.phrases-div1 > div {
  display: flex;
}
.phrases-stats {
  width: 30%;
  margin-bottom: 43px;
}
.phrases-stats > h1 {
  font-size: 20px;
  color: #818383;
  font-weight: 600;
}
/* .phrases-stats > div {
  margin-top: 24px;
  height: 27px;
  border-radius: 24px;
  background-color: #1371e2;
} */

.completeness {
  margin-top: 24px;
  width: 100%;
  height: 27px;
  border-radius: 24px;
  background-color: #1371e2;
}

.accuracy {
  margin-top: 24px;
  width: 100%;
  height: 27px;
  border-radius: 24px;
  background-color: #f4f4f4;
}
.accuracy > div {
  margin-top: 24px;
  width: 90%;
  height: 27px;
  border-radius: 24px;
  background-color: #1371e2;
}

.fluency {
  margin-top: 24px;
  width: 100%;
  height: 27px;
  border-radius: 24px;
  background-color: #f4f4f4;
}
.fluency > div {
  margin-top: 24px;
  width: 96%;
  height: 27px;
  border-radius: 24px;
  background-color: #1371e2;
}

.phrases-stats > p {
  font-size: 16px;
  color: #818383;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 0px;
}
.qAndA-1 {
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  width: 57%;
}
.qAndA-1 > h1 {
  margin: 29px 40px 20px;
  font-size: 20px;
  color: #222323;
  font-weight: 600;
}

.qAndA-1 p {
  font-size: 16px;
  color: #818383;
  margin-left: 40px;
  margin-bottom: 5px;
  font-weight: 400;
}
.qAndA-icons {
  display: flex;
  justify-content: center;
}
.qAndA-icons > div {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.qAndA-icons > div span {
  font-size: 13px;
  color: #818383;
}

.qAndA-2 {
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  width: 57%;
}

.qAndA-2 > h1 {
  margin: 29px 40px 20px;
  font-size: 20px;
  color: #222323;
  font-weight: 600;
}
.qAndA-2 p {
  font-size: 16px;
  color: #818383;
  margin-left: 40px;
  margin-bottom: 5px;
  font-weight: 400;
}

.qAndA-2 p:nth-of-type(1) {
  color: #1371e2;
  font-weight: 600;
}
.qAndA-2 p:nth-of-type(4) {
  margin-top: 16px;
  font-size: 20px;
  color: #1371e2;
  font-weight: 600;
}

.vocabulary-div1 > h3 {
  margin: 40px 59px 12px 40px;
  color: #222323;
  font-weight: 600;
}
.vocabulary-div1 p:nth-of-type(1) {
  font-size: 20px;
  color: #818383;
  margin-left: 40px;
  margin-bottom: 12px;
  font-weight: 600;
}
.vocabulary-div1 p:nth-of-type(2) {
  font-size: 16px;
  color: #818383;
  margin-left: 40px;
  margin-bottom: 12px;
  font-weight: 400;
}
.vocabulary-div1 p:nth-of-type(3) {
  font-size: 16px;
  color: #818383;
  margin: 0px 37px 30px 40px;
  font-weight: 400;
}
.vocabulary-div2 {
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: space-around;
}

.vocabulary-div3 {
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  width: 40%;
}
.vocabulary-div3 > div {
  text-align: center;
  width: 100%;
  height: 32px;
  font-size: 20px;
  font-weight: 600;
  color: #818383;
  margin: 40px 0 10px 0;
}

.vocabulary-div4 {
  text-align: center;
  font-weight: 600;
  margin: 2% !important;
  color: #818383;
}

.vocabulary-div3 p {
  margin: 60px 70px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #1371e2;
}

.vocabulary-div3 h6 {
  text-align: center;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  color: #1371e2;
  padding: 2%;
  width: 250px;
  border: 2px solid #1371e2;
}

/* progress chart css */

#base {
  position: absolute;
  left: 0;
}
#base .MuiCircularProgress-colorPrimary {
  color: #f4f4f4 !important;
}
#value .MuiCircularProgress-colorPrimary {
  color: #1371e2 !important;
}
#value .MuiCircularProgress-colorPrimary svg {
  transform: scaleY(-1);
}
#value {
  position: absolute;
  left: 0;
  z-index: 2;
}
#score span {
  font-size: 33px;
  color: #1371e2;
}
#score {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
}

.MsuiBackdrop-root {
  background-color: transparent !important;
  overflow: auto;
}

/* main section start */

@media only screen and (max-width: 991px) {
  #multilingualImge {
    margin-top: 32px !important;
  }

  #main-div #widget {
    margin-top: 0 !important;
  }
  .main-left {
    text-align: center;
  }
}

.button-main {
  cursor: pointer;
}

.image-area {
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  min-height: 700px;
  background-position-x: 0px;
}

.image-right {
  padding: 160px 0px 0px 130px;
}

.button-main {
  background-color: #1371e2;
  border: none;
  color: #ffffff;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  border-radius: 10px;
}

.link-button {
  background-color: #ffffff;
  border: none;
  color: #1371e2;
  padding: 14px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  margin: 40px;
  border: 1px solid #1371e2;
  border-radius: 40px;
}

.image-right h1 {
  font-size: 42px;
  padding-right: 50px;
  margin-bottom: 8px;
  line-height: 1em;
}

.image-right p {
  line-height: 2em;
  font-family: "Poppins", Sans-serif;
  letter-spacing: 1px;
  padding-right: 90px;
}

.top-button {
  padding: 10px 50px !important;
  font-size: 19px !important;
  text-transform: none !important;
  border-radius: 70px !important;
}

/* widget section start */
.widget {
  display: flex;
  background-color: #ffffff;
}

.widget div {
  background-color: #ffffff;
  margin-left: 120px;
  margin-right: 60px;
  padding: 20px;
}

.widget-section h1 {
  color: #3f4451;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .widget-item {
    text-align: center;
  }
}
.widget-item {
  justify-content: space-between;
  justify-items: center;
  display: flex;
}

.card-wrapper {
  width: 89%;
  margin: auto;
}

.card {
  padding: 28px 40px 22px 40px;
  box-shadow: 0px 10px 30px 0px rgba(20, 3, 67, 0.08);
  border-left: 6px solid #f5bc42 !important;
  border-radius: 6px;
  margin-bottom: 20px;
}

.top-para {
  font-size: 20px;
  line-height: 34px;
  color: #55595c;
}

.bottom-para {
  font-size: 14px;
  color: #848ca5;
  line-height: 20px;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}

.button {
  float: right;
  border-radius: 3px;
  padding: 5px 15px !important;
  text-transform: none !important;
}

/* technology-section start */

.technology-background {
  background-image: url(./images/Technology-background.svg);
  background-position: top right;
  background-repeat: no-repeat;
  background-origin: content-box;
  padding-top: 16px;
}

@media only screen and (max-width: 991px) {
  .technology-section {
    text-align: center;
    padding: 0px !important;
  }
  .technology-section h1 {
    text-align: center !important;
  }
}

/* client-section start*/
.client-section {
  padding-top: 50px;
  background-color: #fbfbfd;
  text-align: center;
}

.client-section h1 {
  color: #3f4451;
  margin-bottom: 40px;
}

.client-section p {
  width: 75%;
  margin: auto;
  color: #3f4451;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 1.6em;
}

.client-section h2 {
  color: #3f4451;
  margin-top: 80px;
  margin-bottom: 10px;
}

.image-section {
  flex: 20%;
  max-width: 20%;
  padding: 40px 0px;
  margin: auto;
}

@media only screen and (max-width: 991px) {
  .image-section {
    flex: 25%;
    max-width: 25%;
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 768px) {
  .presentation-stats {
    width: 100%;
    margin: auto;
    padding-right: 16px;
  }
  .presentationContainer {
    flex-direction: column;
    width: auto;
  }
  .phrases-stats {
    width: 100%;
  }
  .phrases-div1 > div {
    display: flex;
    flex-direction: column;
  }
  .qAndA > h1 {
    margin-bottom: 16px;
  }
  .qAndA-2,
  .qAndA-1 {
    width: 90%;
  }
  .qAndA-icons {
    align-items: center;
    justify-content: space-around;
  }
  .qAndA-icons > div {
    margin: 0 !important;
    margin-bottom: 20px !important;
  }
  .qAndA-2 > h1,
  .qAndA-2 p:nth-of-type(1),
  .qAndA-2 p,
  .qAndA-2 p,
  .qAndA-2 p:nth-of-type(4),
  .qAndA-2 p,
  .qAndA-1 > h1,
  .qAndA-1 p:nth-of-type(1),
  .qAndA-1 p,
  .qAndA-1 p,
  .qAndA-1 p:nth-of-type(4),
  .qAndA-1 p {
    margin: 16px;
  }
  .qAndA {
    width: 80%;
    height: 80%;
    overflow: auto;
    padding-bottom: 40px;
  }
  .vocabulary {
    width: 80%;
    max-height: 80%;
    overflow: auto;
  }
  .presentation {
    width: 80%;
    max-height: 80%;
    overflow: auto;
  }
  .phrases {
    width: 80%;
    max-height: 80%;
    overflow: auto;
  }
  .vocabulary-div2 > svg {
    display: none;
  }
  .vocabulary-div1 {
    width: 80%;
  }
  .vocabulary-div2 {
    height: 100px;
  }
  .vocabulary-div1,
  .vocabulary-div3 {
    width: 80%;
  }
  .vocabulary > h1 {
    margin-bottom: 32px;
  }
  .vocabulary-div1 > h3,
  .vocabulary-div1 p:nth-of-type(1),
  .vocabulary-div1 p:nth-of-type(2),
  .vocabulary-div1 p:nth-of-type(3) {
    margin-left: 16px;
  }
  #vocabularyMain {
    margin: 0;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .vocabulary-div2 {
    flex-direction: column;
  }
  .vocabulary-div2 > svg {
    margin: 0 !important;
  }
  .image-section {
    flex: 33%;
    max-width: 33%;
    overflow-y: hidden;
  }
}

/* card-section start */

.card-main {
  padding: 0px 20px;
}

.card-image {
  width: 100%;
}
.card-section {
  text-align: center;
}

.card-section-top h1 {
  color: #222323;
  margin: 40px;
}

.card-section-top p {
  color: #9b9da4;
  font-size: 16px;
  line-height: 1.7em;
  letter-spacing: 1px;
}

.card-content {
  background-color: #fbfbfd;
  padding: 20px 10px;
}

.card-section h3 {
  color: #f0800e;
  font-size: 23px;
  letter-spacing: 2px;
  margin-top: 0px;
}

.card-content p {
  color: #677294;
  line-height: 1.6em;
  letter-spacing: 1px;
  font-size: 15px;
}

.card-content a {
  color: #f0800e;
  text-decoration: none;
}

/* blog section start */

.blog-paper {
  padding: "120px 80px" !important;
  margin-left: "15px" !important;
  margin-right: "15px" !important;
}

.blog-paper:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);

  transition: 0.3s;
  background-color: #1371e2;
  color: #fff !important;
}

.blog-paper-comming {
  padding: "180px 60px";
  margin-left: "15px";
  margin-right: "15px";
}

.blog-paper-tech:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
  transition: 0.3s;
}

@media only screen and (max-width: 991px) {
  .blog-paper {
    margin-bottom: 30px;
  }

  .tech-blue-band {
    padding: 60px 10px !important;
  }
  .tech-green-band {
    padding: 0px 5px !important;
  }
  .tech-img-feature {
    position: initial !important;
    margin-bottom: 80px;
  }
  .tech-lower-band {
    padding: 80px 30px !important;
  }
}

.blog-section {
  padding: 140px 0px;
  background-color: #f8fafe;
}

.blog-section h1 {
  text-align: center;
  /* color: #3f4451; */
}

.blog-content {
  padding: 40px 40px 60px 80px;
  background-color: #fff;
}

.blog-content p {
  color: #677294;
  font-weight: 400;
  font: 300 15px/28px "Poppins", sans-serif;
  font-size: 18px;
  line-height: 1.8em;
}

.blog-image {
  display: block;
  margin: auto;
  width: 68%;
  padding-top: 70px;
  margin-bottom: 25px;
}

.news-button {
  background-color: #f0800e;
  color: white;
}

/* media section start */
.media-section {
  background-color: blue;
  padding: 50px 0px;
}

.media-section h1 {
  text-align: center;
  color: #3f4451;
}

.media-image {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 50px;
  width: 80%;
  margin: auto;
}

.media-image-section {
  flex: 25%;
  max-width: 25%;
  padding: 40px 0px;
  margin: auto;
}

.image {
  width: 80%;
}

/* subscribe section */
.subscribe-section {
  padding: 90px 0px;
}
.subscribe-section h1 {
  text-align: center;
  color: #3f4451;
  margin-bottom: 80px;
  font-size: 30px;
}

.subscribe-section input[type="text"] {
  border: none;
  border: 1px solid #b7b8bd;
  padding: 12px;
  width: 25%;
  color: #7b7b7b;
  border-radius: 4px;
}

.btn-section {
  display: flex;
  justify-content: space-between;
}

.btn-section-right {
  display: flex;
  margin-right: 4%;
}

.record-btn-wrapper {
  float: right;
}

@media only screen and (max-width: 991px) {
  .btn-section {
    display: block;
    text-align: center;
  }
  .btn-section-right {
    text-align: center;
    margin-right: 0;
    justify-content: center !important;
  }
  .record-btn-section {
    text-align: center;
  }

  .record-btn-wrapper {
    float: initial;
  }
  .record-btn {
    float: initial !important;
    margin: auto !important;
  }
}

.demo-btn {
  background-color: #f4f4f4;
  border-radius: 50px;
  padding: 0px 20px;
}

.demo-btn li {
  display: inline;
  padding: 2px 15px;
  cursor: pointer;
  border-radius: 20px;
}

.demo-sentence {
  font-weight: 500;
  color: #373b3b;
  font-size: 28px;
  display: flex;
  justify-content: center;
}

.demo-sentence:hover .demo-smallcard {
  display: block;
}

.demo-smallcard {
  border: 2px solid #eeeeee;
  padding: 15px;
  border-radius: 28px;
  position: absolute;
  z-index: 100;
  background: white;
  width: 130px;
  display: none;
  margin-top: 40px;
  font-size: 14px;
  margin-top: 50px;
}

.engbtn {
  background-color: #1371e2;
  color: #fff;
}

.dark {
  color: #000;
}
.light {
  color: #b9b9b9;
}

.chibtn {
  background-color: #1371e2;
  color: #fff;
}

.canvas-section {
  height: 200px !important;
}

/* .canvas-section-vertical{
	height: 40vh !important;
} */

/* footer section start */

.footer {
  padding: 80px 65px;
  background-color: #fbfbfd;
}

.footer-image {
  margin-bottom: 45px;
}

.footer h2 {
  color: #3f4451;
  margin-bottom: 50px;
  margin-top: 0px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", Sans-serif;
}

.footer-list li {
  margin-bottom: 10px;
}

.footer ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.footer ul li {
  margin-bottom: 30px;
  color: #677294;
}

.footer span {
  color: #677294;
}

@media only screen and (max-width: 991px) {
  .countdown-div {
    justify-content: center;
  }
  .countdown {
    font-size: 25px;
    padding: 24px 16px !important;
    margin-bottom: 8px !important;
  }
  .pronunciation img {
    height: 368px !important;
    width: 100% !important;
  }
  .input-email button {
    height: 56px !important;
    width: 100% !important;
    margin-left: 0px !important;
  }
  .input-email input {
    height: 56px !important;
    width: 100% !important;
    margin-bottom: 8px !important;
    margin-top: 32px !important;
  }
  .input-email {
    margin-top: 48px !important;
  }
  #speakPhrase + p {
    margin-bottom: 24px !important;
  }
  #speakPhrase {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #818383 !important;
    margin-bottom: 48px !important;
  }
  .pronunciation h1 {
    margin-top: 40px !important;
    font-size: 32px !important;
  }
  .pronunciation h2 {
    margin-top: 40px;
  }
  .pronunciation {
    padding-top: 48px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
    margin-bottom: 0px !important;
  }
  .footer-lower {
    padding: 80px 0px !important;
  }
}

.card-flex {
  display: flex;
  background-color: #ffffff;
}

.card-flex div {
  background-color: #ffffff;
  margin-left: 200px;
  margin-top: 140px;
  margin-bottom: 100px;

  width: 550px;
  padding: 50px;
  text-align: left;
  box-shadow: 1px solid #0362cb !important;
}

.card-button {
  color: #d8d8d8;
  width: 124px;
  height: 48px;
  margin: 10px;
}

.media-flex {
  display: flex;
  background-color: #ffffff;
}

.media-flex div {
  background-color: #ffffff;
  margin: 10px;

  padding: 20px;
}

/* technology page */
.btn-animate {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 5px 0px rgba(0, 89, 173, 0.3);
  }
  65% {
    box-shadow: 0px 0px 5px 13px rgba(0, 81, 173, 0.3);
  }
  90% {
    box-shadow: 0px 0px 5px 13px rgba(0, 75, 173, 0);
  }
}

@media only screen and (max-width: 991px) {
  .feature-title {
    margin-bottom: 10px !important;
  }
  .feature-img {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .tech-flow-heading {
    padding: 0px !important;
  }
}

/* .vertical-parent{
	position: relative;
} */
/* 
.vertical-children{
	position: relative;
} */

/* contact page */

.contact-input {
  width: 100%;
  background-color: #fff;
  border: none;
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  padding: 10px 15px;
  margin-top: 8px;
  margin-bottom: 20px;
}

.contact-input:focus {
  outline: none;
  border: 1px solid #0149b4;
}

.contact-btn {
  background-color: #1371e2;
}

input:focus {
  outline: none;
}

/* pronunciation challenge */
.pronunciation-input {
  background-color: #fff;
  border: none;
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  padding: 10px 15px;
  margin-top: 8px;
  margin-bottom: 20px;
}
@media (min-width: 991px) {
  #main-div {
    background-repeat: no-repeat;
    background-position: top right;
  }
}

#soundSymbols > div,
#soundLevelScore > div,
#soundLevelGraph > div {
  margin: 0 20px;
  min-width: 40px;
}

@media (min-width: 992px) {
  #technologyFeatureContainer-desktop {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  #technologyFeatureContainer {
    display: none;
  }
  h1.mobile-blogPaperComing {
    display: none;
  }
  h2.full-WidthGrid ~ p {
    color: #818383;
    font-size: 16px;
    max-width: 225px;
    margin: auto;
  }
  .blog-paper-main img {
    width: 100%;
    height: 50px;
  }
  .blog-paper-main {
    margin-left: 15px;
    margin-right: 15px;
    height: 328px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .blogPaperComing {
    margin-left: 15px;
    margin-right: 15px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #mobile-technology {
    display: none;
  }
  .contactBg {
    background-image: url(./images/Contact_Background.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  #schoolAndEducation {
    display: none;
  }
  #mobileLanding {
    display: none;
  }
  .MuiContainer-maxWidthLg {
    max-width: unset !important;
    padding-right: 0px !important;
    margin: 0 140px !important;
    width: auto !important;
  }
  #main-div {
    margin-top: 67px;
    background-image: url(./images/landing-top.svg);
    background-size: 805px 654px;
    background-position: top right;
  }
}

@media (min-width: 426px) {
  #technology-row {
    margin-top: 80px;
  }
  div.footer-mobile {
    display: none;
  }
  .blog-paper-tech {
    margin-bottom: 30px;
    padding: 80px 0px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .recording-text {
    display: none;
  }
  .dot {
    display: none;
  }
  .tech-green-band h1 {
    font-weight: bold;
    font-size: 31px;
    padding: 0px 60px;
    max-width: 920px;
    margin: auto;
    padding: 0;
  }
  #mobileGraphApi {
    display: none;
  }
  #techMain {
    padding: 130px 0px;
    text-align: center;
  }
  #tech-flow {
    padding: 150px 0px;
  }

  .techMainContainer {
    padding-top: 200px;
  }
  #aispeakLogo {
    height: 40px;
  }
}

@media (max-width: 425px) {
  #contactbg .form-section {
    width: 90% !important;
  }
  #contactbg .form-section button {
    width: 100%;
  }
  .footer-bar {
    cursor: pointer;
    height: 1px;
    width: 290px;
    margin: 0px 15px 0px 15px;
    background-color: #ffffff;
    display: inline-block;
    transition: background-color 0.6s ease;
    display: flex;
    margin: auto;
    width: 80%;
    margin-top: 16px;
  }
  .footer-list li {
    margin-bottom: 16px;
  }
  #technologyFeatureContainer {
    padding-bottom: 56px !important;
  }
  .tech-blue-band {
    margin-top: 18px;
  }
  .footer-desktop {
    display: none;
  }
  .feature-title {
    margin-bottom: 48px !important;
  }
  .technologyFeature {
    padding-bottom: 80px !important;
  }
  #technologyFeatureImage {
    margin-top: 0px !important;
  }
  .technologyFeature img {
    margin-top: 50px;
  }
  .technologyFeature h2 {
    margin-top: 32px !important;
  }
  .blog-paper-tech p {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .blog-paper-tech h1 {
    font-size: 32px;
    font-weight: 600 !important;
  }

  div.technology-usecase {
    padding-bottom: 0px !important;
  }
  div.techMainContainer p {
    margin-bottom: 28px;
  }
  .technology-usecase {
    margin-top: 80px !important;
  }
  .technology-usecase img {
    max-height: 132px !important;
    margin-bottom: 44px !important;
  }
  .technology-usecase img:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
    transition: 0.3s;
  }
  .technology-usecase h2 {
    margin-bottom: 80px !important;
    font-weight: 600;
  }
  .technology-usecase > h1 {
    margin-bottom: 68px !important;
    font-size: 25px;
    font-weight: 600;
  }
  /* .MuiContainer-root.MuiContainer-maxWidthLg {
    padding: 0px !important;
  } */
  .tech-img-feature {
    margin-bottom: 24px;
  }
  div.tech-img-feature p {
    margin-top: 4px;
    margin-bottom: 24px;
    text-align: center !important;
    margin: auto;
  }
  .tech-img-feature h5 {
    text-align: center !important;
  }
  .feature-title {
    font-size: 25px;
    font-weight: 600;
    color: #222323;
  }
  .feature-graph {
    padding: 0px !important;
  }
  .dark,
  .light {
    font-size: 13px !important;
  }
  #demo > h1 {
    font-size: 25px !important;
    margin-top: 80px !important;
  }
  .demo-container {
    padding: 20px 15px !important;
  }
  .recording-text-desktop {
    display: none;
  }
  .record-timer {
    margin-bottom: 0px;
  }
  .tech-green-band h1 {
    font-weight: 600;
    font-size: 20px;
    padding: 48px 20px 48px 20px;
  }
  .tech-flow-heading {
    margin-bottom: 44px !important;
  }
  .carouselPara {
    margin-top: 16px;
    margin-bottom: 8px;
    color: #0149b4;
    font-size: 12.8px;
    font-weight: 600;
  }
  .dot {
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    margin-bottom: 16px;
    height: 32px;
    width: 32px;
    background-color: #1371e2;
    border-radius: 50%;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 12.8px;
  }
  .bar {
    cursor: pointer;
    height: 2px;
    width: 40px;
    margin: 0 2px;
    background-color: #b9b9b9;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  .bar.active {
    background-color: #1371e2;
  }

  .carousel .slide {
    background-color: transparent !important;
  }
  #desktopGraphApi {
    display: none;
  }
  #techMain .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  #tech-flow {
    padding: 80px 0px;
  }
  .blog-paper-tech {
    margin-bottom: 30px;
    padding: 32px 0px;
    margin-left: 15px;
    margin-right: 15px;
  }
  #techMain {
    padding: 0px;
    text-align: center;
  }
  #aispeakLogo {
    width: 92px;
  }
  .techMainContainer {
    padding-top: 80px;
  }
  .technology-background {
    background-image: none;
  }
  .techTitle {
    font-size: 31px !important;
  }

  #desktopLanding {
    display: none;
  }
  #soundWave {
    width: 100%;
    padding-top: 0 !important;
  }
  #propMessage {
    font-size: 25px !important;
    font-weight: 500 !important;
  }
}
@media (min-width: 426px) {
  #mobileSchoolAndEducation {
    display: none;
  }
}

#soundWave {
  width: 100%;
  padding-top: 28px;
}

#root {
  overflow-x: hidden;
}

button.navbar-toggler.collapsed {
  border: none;
}

ul.thumbs {
  display: none;
}

ul.control-dots {
  display: none;
}

p.carousel-status {
  display: none;
}

.technology-usecase h2 {
  font-weight: 600;
}

.tech-usecase-img-container img {
  width: 100%;
}
#tech-usecase-img-speech img {
  width: 100%;
}

.tech-usecase-img-container {
  padding: 24px 72px;
  margin-bottom: 24px;
  height: 208px;
  display: flex;
}

#tech-usecase-img-speech {
  padding: 24px 72px;
  margin-bottom: 24px;
  height: 208px;
  display: flex;
}

.tech-usecase-img-container:hover {
  margin-bottom: 24px;
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4); */
  box-shadow: 0px 5px 10px #34649a;
  transition: 0.3s;
  cursor: pointer;
}

#aispeakCanBeUsed {
  margin: 0 !important;
}

.tech-lower-band p {
  margin-top: 24px;
  margin-bottom: 44px;
}

.tech-lower-band h1 {
  font-weight: 800;
}

#phrasesContainer {
  margin-left: 40px;
  margin-right: 40px;
  justify-content: space-between;
}
